<template>
  <v-container id="dashboard-view" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <h1>The content still to be generated...</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard3',

  data: () => ({
  }),

  computed: {
  },

  mounted () {
  }
}
</script>

<style lang="scss">
</style>
